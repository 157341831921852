import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RedeemVoucherForm from './components/RedeemVoucherForm';

function App() {
  return (
    <ChakraProvider>
      <Box bg="gray.50" minH="100vh" p={10}>
        <Router>
          <Routes>
            <Route path="/redeem/:pvc" element={<RedeemVoucherForm />} />
          </Routes>
        </Router>
      </Box>

    </ChakraProvider>
  );
}

export default App;
