import React, { useEffect, useState, useRef } from 'react';
import {
    VStack,
    Image,
    Text,
    Button,
    Input,
    FormControl,
    FormLabel,
    useToast,
    Divider,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Access environment variables
const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const RedeemVoucherForm = () => {
    const { pvc } = useParams();
    const [promoId, setPromoId] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [isValid, setIsValid] = useState(true); // State to track if voucher is valid
    const [isRedeemed, setIsRedeemed] = useState(false); // State to track if voucher has already been redeemed
    const [expiryDate, setExpiryDate] = useState(''); // State to store expiry date
    const [promoImage, setPromoImage] = useState(''); // State to store promo image URL
    const [promoTitle, setPromoTitle] = useState(''); // State to store promo title
    const [promoDescription, setPromoDescription] = useState(''); // State to store promo description
    const [promoExpiredAt, setPromoExpiredAt] = useState(''); // State to store promo expiration date
    const toast = useToast();

    // For the confirmation dialog
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const cancelRef = useRef();

    useEffect(() => {
        // Extract p, v, c from pvc
        const [customerIdParam, promoIdParam, voucherCodeParam] = pvc.split('_');

        if (promoIdParam) setPromoId(promoIdParam);
        if (voucherCodeParam) setVoucherCode(voucherCodeParam);
        if (customerIdParam) setCustomerId(customerIdParam);

        // Fetch promotion details
        if (promoIdParam) {
            fetchPromotion(promoIdParam);
        }

        // Validate voucher
        if (promoIdParam && customerIdParam) {
            validateVoucher(promoIdParam, customerIdParam, voucherCodeParam);
        }
    }, [pvc]);

    const fetchPromotion = async (promo_id) => {
        try {
            const response = await axios.get(`${apiUrl}/promotions/${promo_id}`, {
                headers: {
                    'x-api-key': apiKey
                }
            });
            const { promo_image_url, promo_title, promo_description, promo_expired_at } = response.data.data;
            setPromoImage(promo_image_url);
            setPromoTitle(promo_title);
            setPromoDescription(promo_description);
            setPromoExpiredAt(promo_expired_at);
        } catch (error) {
            console.error("Error fetching promotion:", error);
        }
    };

    const validateVoucher = async (customer_id, promo_id, code) => {
        try {
            let path = '';
            if (code && code.length > 0) {
                path = `vouchers/redeem/${promo_id}_${customer_id}_${code}`;
            }
            else {
                path = `vouchers/redeem/${promo_id}_${customer_id}`;
            }

            const response = await axios.get(`${apiUrl}/${path}`, {
                headers: {
                    'x-api-key': apiKey
                }
            });
            if (response.status === 200 && response.data.status === 200) {
                setIsValid(true);
                setIsRedeemed(false);
                setExpiryDate(''); // Clear the expiry date when voucher is valid and not redeemed
                setVoucherCode(response.data.data.code); // Set voucher code from API response
            } else {
                setIsValid(false);
                setIsRedeemed(true);
                setExpiryDate(response.data.data.created_at); // Set expiry date from API response
                setVoucherCode(response.data.data.code); // Set voucher code from API response
            }
        } catch (error) {
            setIsValid(false);
            setIsRedeemed(true);
            setExpiryDate(''); // Clear the expiry date in case of error
            setVoucherCode(''); // Clear the voucher code in case of error
        }
    };

    const handleRedeem = async () => {
        try {
            const response = await axios.post(`${apiUrl}/vouchers/redeem/`, {
                code: voucherCode,
                customer_id: customerId,
            }, {
                headers: {
                    'x-api-key': apiKey
                }
            });
            toast({
                title: "Voucher redeemed.",
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setIsRedeemed(true); // Update state if redemption is successful
            setExpiryDate(response.data.data.created_at); // Set expiry date from API response
        } catch (error) {
            toast({
                title: "Error redeeming voucher.",
                description: error.response?.data?.detail || "An error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsDialogOpen(false); // Close the confirmation dialog
        }
    };

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    return (
        <>
            <VStack spacing={4} p={1} maxW="sm" mx="auto" borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="white">
                <VStack p={5}>
                    <Image
                        src={promoImage || '/logo192.png'}
                        alt="Promo Image"
                        borderRadius="md"
                        onError={(e) => { e.target.onerror = null; e.target.src = '/logo192.png'; }}
                    />
                    <Text fontWeight="bold" fontSize="xl" mt={2}>{promoTitle || "Beli 6 Gratis 6 Donut"}</Text>
                    <Text color="gray.500" fontSize="sm">{promoDescription || "Tukar kupon digital ini di outlet (your brand)."}</Text>

                    <FormControl id="voucher-code" mt={4} textAlign="center">
                        <FormLabel fontSize="lg" fontWeight="bold" textAlign="center">Voucher Code</FormLabel>
                        <Input
                            type="text"
                            value={voucherCode}
                            isReadOnly
                            fontSize="2xl"
                            textAlign="center"
                        />
                    </FormControl>
                </VStack>
                <Divider borderStyle="dashed" borderWidth="2px" borderColor="gray.300" />
                <VStack p={5}>
                    <Button
                        colorScheme={isRedeemed ? 'gray' : 'red'}
                        size="lg"
                        width="full"
                        onClick={openDialog}
                        isDisabled={!isValid || isRedeemed} // Disable if invalid or already redeemed
                    >
                        {isRedeemed ? "KUPON SUDAH DITUKAR" : "TUKAR KUPON"}
                    </Button>
                    <Text color="gray.500" fontSize="xs">
                        {isRedeemed ? `Ditukar : ${expiryDate}` : `Berlaku hingga: ${promoExpiredAt || '13 Oct - 13 Oct'}`}
                    </Text>
                </VStack>
            </VStack>

            <AlertDialog
                isOpen={isDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={closeDialog}
                isCentered // This prop centers the dialog on the screen
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Redeem Kupon
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to redeem this Kupon?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeDialog}>
                                No
                            </Button>
                            <Button colorScheme="red" onClick={handleRedeem} ml={3}>
                                Yes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default RedeemVoucherForm;
